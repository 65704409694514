/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addItemPrice
// ====================================================

export interface addItemPrice_addItemPrice {
  __typename: 'ItemPrice';
  id: number;
}

export interface addItemPrice {
  addItemPrice: addItemPrice_addItemPrice;
}

export interface addItemPriceVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSetPrice
// ====================================================

export interface addSetPrice_addSetPrice {
  __typename: 'SetPrice';
  id: number;
}

export interface addSetPrice {
  addSetPrice: addSetPrice_addSetPrice;
}

export interface addSetPriceVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCategory
// ====================================================

export interface AddCategory_addCategory {
  __typename: 'Category';
  id: number;
}

export interface AddCategory {
  addCategory: AddCategory_addCategory;
}

export interface AddCategoryVariables {
  name: string;
  itemsExcludedFromSet?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCategory
// ====================================================

export interface EditCategory_editCategory {
  __typename: 'Category';
  id: number;
  name: string;
  itemsExcludedFromSet: boolean;
  imagePath: string | null;
}

export interface EditCategory {
  editCategory: EditCategory_editCategory;
}

export interface EditCategoryVariables {
  input: EditCategoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addCatalogGroup
// ====================================================

export interface addCatalogGroup_addCatalogGroup {
  __typename: 'CatalogGroupsResult';
  id: string;
}

export interface addCatalogGroup {
  addCatalogGroup: addCatalogGroup_addCatalogGroup;
}

export interface addCatalogGroupVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteCatalogItem
// ====================================================

export interface deleteCatalogItem {
  deleteCatalogItem: boolean | null;
}

export interface deleteCatalogItemVariables {
  catalogItemId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeOrderDate
// ====================================================

export interface changeOrderDate {
  changeOrderDate: boolean | null;
}

export interface changeOrderDateVariables {
  order: DateChangeInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setMorningPickUp
// ====================================================

export interface setMorningPickUp_setMorningPickUp {
  __typename: 'PickUpRetrunType';
  morningPickUp: boolean | null;
  orderPrice: number | null;
}

export interface setMorningPickUp {
  setMorningPickUp: setMorningPickUp_setMorningPickUp | null;
}

export interface setMorningPickUpVariables {
  orderId: number;
  morningPickUp?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setEveningReturn
// ====================================================

export interface setEveningReturn_setEveningReturn {
  __typename: 'ReturnReturnType';
  eveningReturn: boolean | null;
  orderPrice: number | null;
}

export interface setEveningReturn {
  setEveningReturn: setEveningReturn_setEveningReturn | null;
}

export interface setEveningReturnVariables {
  orderId: number;
  eveningReturn?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFollowingOrder
// ====================================================

export interface createFollowingOrder_createFollowingOrder {
  __typename: 'Order';
  id: number;
}

export interface createFollowingOrder {
  createFollowingOrder: createFollowingOrder_createFollowingOrder;
}

export interface createFollowingOrderVariables {
  from: any;
  to: any;
  orderId: number;
  season: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: extendCurrentOrder
// ====================================================

export interface extendCurrentOrder_extendCurrentOrder {
  __typename: 'Order';
  id: number;
}

export interface extendCurrentOrder {
  extendCurrentOrder: extendCurrentOrder_extendCurrentOrder;
}

export interface extendCurrentOrderVariables {
  to: any;
  orderId: number;
  price: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createCatalogItem
// ====================================================

export interface createCatalogItem_createCatalogItem_category {
  __typename: 'Category';
  id: number;
  name: string;
}

export interface createCatalogItem_createCatalogItem {
  __typename: 'CatalogItem';
  id: number;
  category: createCatalogItem_createCatalogItem_category;
  purpose: string | null;
  name: string;
  size: string;
  selectSize: number | null;
  variant: string;
  producer: string;
  note: string | null;
  pair: boolean | null;
  price: number | null;
  settings: boolean | null;
  createsSet: boolean | null;
  itemPriceId: number | null;
  setPriceId: number | null;
}

export interface createCatalogItem {
  createCatalogItem: createCatalogItem_createCatalogItem;
}

export interface createCatalogItemVariables {
  catalogItem: CreateCatalogItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCatalogItem
// ====================================================

export interface updateCatalogItem_updateCatalogItem_category {
  __typename: 'Category';
  id: number;
  name: string;
}

export interface updateCatalogItem_updateCatalogItem {
  __typename: 'CatalogItem';
  id: number;
  category: updateCatalogItem_updateCatalogItem_category;
  purpose: string | null;
  name: string;
  size: string;
  selectSize: number | null;
  variant: string;
  producer: string;
  note: string | null;
  pair: boolean | null;
  price: number | null;
  settings: boolean | null;
  createsSet: boolean | null;
  itemPriceId: number | null;
  setPriceId: number | null;
}

export interface updateCatalogItem {
  updateCatalogItem: updateCatalogItem_updateCatalogItem;
}

export interface updateCatalogItemVariables {
  catalogItem: UpdateCatalogItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addCatalogItem
// ====================================================

export interface addCatalogItem {
  addCatalogItemToCatalogGroup: boolean;
}

export interface addCatalogItemVariables {
  catalogItemIds: number[];
  catalogGroupId: number;
  positionInCatalogGroup: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeCatalogItem
// ====================================================

export interface removeCatalogItem {
  removeCatalogItemFromCatalogGroup: boolean;
}

export interface removeCatalogItemVariables {
  catalogItemIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setCatalogItem
// ====================================================

export interface setCatalogItem {
  setPositionOfCatalogItemsInCatalogGroup: boolean;
}

export interface setCatalogItemVariables {
  catalogItemIds: number[];
  positionInCatalogGroup: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CanCatalogItemBeInSet
// ====================================================

export interface CanCatalogItemBeInSet {
  canCatalogItemBeInSet: boolean | null;
}

export interface CanCatalogItemBeInSetVariables {
  setId: number;
  catalogId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCatalogItem
// ====================================================

export interface getCatalogItem_catalogItem_category {
  __typename: 'Category';
  id: number;
  name: string;
}

export interface getCatalogItem_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  category: getCatalogItem_catalogItem_category;
  purpose: string | null;
  name: string;
  size: string;
  selectSize: number | null;
  variant: string;
  producer: string;
  note: string | null;
  pair: boolean | null;
  price: number | null;
  settings: boolean | null;
  createsSet: boolean | null;
  itemPriceId: number | null;
  setPriceId: number | null;
  seasonDisabled: boolean | null;
}

export interface getCatalogItem {
  catalogItem: getCatalogItem_catalogItem | null;
}

export interface getCatalogItemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSetCatalogItems
// ====================================================

export interface getSetCatalogItems_getSetCatalogItems {
  __typename: 'CatalogItem';
  id: number;
  purpose: string | null;
  name: string;
  categoryName: string | null;
  size: string;
  selectSize: number | null;
  variant: string;
  producer: string;
  note: string | null;
  pair: boolean | null;
  createsSet: boolean | null;
  settings: boolean | null;
  price: number | null;
}

export interface getSetCatalogItems {
  getSetCatalogItems: (getSetCatalogItems_getSetCatalogItems[] | null)[] | null;
}

export interface getSetCatalogItemsVariables {
  ids?: number[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddItemToSet
// ====================================================

export interface AddItemToSet_addItemToSet {
  __typename: 'Set';
  id: number;
}

export interface AddItemToSet {
  addItemToSet: AddItemToSet_addItemToSet | null;
}

export interface AddItemToSetVariables {
  setId: number;
  itemId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveSetFromOrder
// ====================================================

export interface RemoveSetFromOrder_removeSetFromOrder {
  __typename: 'Order';
  id: number;
}

export interface RemoveSetFromOrder {
  removeSetFromOrder: RemoveSetFromOrder_removeSetFromOrder | null;
}

export interface RemoveSetFromOrderVariables {
  setId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveItemFromSet
// ====================================================

export interface RemoveItemFromSet_removeItemFromSet {
  __typename: 'Set';
  id: number;
}

export interface RemoveItemFromSet {
  removeItemFromSet: RemoveItemFromSet_removeItemFromSet;
}

export interface RemoveItemFromSetVariables {
  setId: number;
  itemId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: accountingSummary
// ====================================================

export interface accountingSummary_accountingSummary_ordersSummary {
  __typename: 'OrderSummary';
  orderId: string;
  customerName: string | null;
  customerPhone: string | null;
  priceCash: number | null;
  priceCard: number | null;
  priceInvoice: number | null;
  priceOnline: number | null;
  priceDPH: number | null;
  bailCash: number | null;
  bailCard: number | null;
  bailInvoice: number | null;
  bailReturnedSum: number | null;
}

export interface accountingSummary_accountingSummary_sums {
  __typename: 'AccountingSums';
  priceCash: number | null;
  priceCard: number | null;
  priceInvoice: number | null;
  priceOnline: number | null;
  bailCash: number | null;
  bailCard: number | null;
  bailInvoice: number | null;
  bailReturnedSum: number | null;
  priceDPH: number | null;
}

export interface accountingSummary_accountingSummary {
  __typename: 'AccountingSummary';
  ordersSummary:
    | (accountingSummary_accountingSummary_ordersSummary | null)[]
    | null;
  sums: accountingSummary_accountingSummary_sums | null;
}

export interface accountingSummary {
  accountingSummary: accountingSummary_accountingSummary | null;
}

export interface accountingSummaryVariables {
  from?: any | null;
  to?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: loadCatalogItems
// ====================================================

export interface loadCatalogItems_catalogItems_items_category {
  __typename: 'Category';
  id: number;
  name: string;
}

export interface loadCatalogItems_catalogItems_items_catalogGroup {
  __typename: 'CatalogGroup';
  id: string;
  name: string;
  code: string | null;
}

export interface loadCatalogItems_catalogItems_items {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  producer: string;
  category: loadCatalogItems_catalogItems_items_category;
  purpose: string | null;
  variant: string;
  size: string;
  selectSize: number | null;
  stockItemsCount: number | null;
  borrowed: number;
  seasonDisabled: boolean | null;
  imagePath: string | null;
  catalogGroup: loadCatalogItems_catalogItems_items_catalogGroup | null;
}

export interface loadCatalogItems_catalogItems {
  __typename: 'CatalogPageResult';
  totalCount: number;
  items: (loadCatalogItems_catalogItems_items | null)[] | null;
}

export interface loadCatalogItems {
  catalogItems: loadCatalogItems_catalogItems | null;
}

export interface loadCatalogItemsVariables {
  offset?: number | null;
  limit?: number | null;
  sort?: string | null;
  order?: string | null;
  search?: string | null;
  barCode?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: stockItems
// ====================================================

export interface stockItems_stockItems_items_catalogItem {
  __typename: 'CatalogItem';
  name: string;
}

export interface stockItems_stockItems_items {
  __typename: 'StockItem';
  code: string;
  catalogItem: stockItems_stockItems_items_catalogItem;
}

export interface stockItems_stockItems {
  __typename: 'StockPageResult';
  totalCount: number;
  items: stockItems_stockItems_items[];
}

export interface stockItems {
  stockItems: stockItems_stockItems;
}

export interface stockItemsVariables {
  offset?: number | null;
  limit?: number | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: employees
// ====================================================

export interface employees_employees_employees_user {
  __typename: 'User';
  id: number;
  name: string | null;
  username: string | null;
}

export interface employees_employees_employees {
  __typename: 'Employee';
  id: number;
  role: EmployeeRole | null;
  user: employees_employees_employees_user | null;
}

export interface employees_employees {
  __typename: 'EmployeesPageResult';
  totalCount: number;
  employees: (employees_employees_employees | null)[] | null;
}

export interface employees {
  employees: employees_employees;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClosedDays
// ====================================================

export interface ClosedDays_closedDays {
  __typename: 'ClosedDay';
  id: number;
  from: any;
  to: any;
  recurring: RecurringType;
  note: string | null;
}

export interface ClosedDays {
  closedDays: (ClosedDays_closedDays | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addClosedDay
// ====================================================

export interface addClosedDay_addClosedDay {
  __typename: 'ClosedDayResult';
  id: number;
}

export interface addClosedDay {
  addClosedDay: addClosedDay_addClosedDay | null;
}

export interface addClosedDayVariables {
  from: any;
  to: any;
  recurring: RecurringType;
  note?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: loadOrders
// ====================================================

export interface loadOrders_orders_items_overbooked {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  producer: string;
  size: string;
}

export interface loadOrders_orders_items_deliveryAddress {
  __typename: 'DeliveryAddress';
  id: string;
  streetName: string;
  streetNumber: string;
  city: string;
  zipCode: string;
}

export interface loadOrders_orders_items_discount {
  __typename: 'Discount';
  id: number;
  discount: number | null;
}

export interface loadOrders_orders_items_payments {
  __typename: 'Payment';
  id: string;
  amount: number;
  type: PaymentType;
  createdAt: any;
}

export interface loadOrders_orders_items_customer {
  __typename: 'Customer';
  id: string;
  name: string;
  phone: string;
}

export interface loadOrders_orders_items_sets_client {
  __typename: 'Client';
  id: number;
  age: number | null;
  weight: number | null;
  height: number | null;
  fitness: string | null;
  snowboardPosition: SnowboardPosition | null;
}

export interface loadOrders_orders_items_sets {
  __typename: 'Set';
  id: number;
  category: string | null;
  client: loadOrders_orders_items_sets_client | null;
}

export interface loadOrders_orders_items {
  __typename: 'Order';
  id: number;
  from: any;
  to: any;
  season: boolean | null;
  lostAndFound: boolean | null;
  overbooked: loadOrders_orders_items_overbooked[] | null;
  deliveryAddress: loadOrders_orders_items_deliveryAddress | null;
  orderOriginator: string | null;
  bail: number | null;
  price: number | null;
  discount: loadOrders_orders_items_discount | null;
  withoutBail: boolean | null;
  payments: (loadOrders_orders_items_payments | null)[] | null;
  customer: loadOrders_orders_items_customer;
  state: OrderState;
  note: string | null;
  eetReceipt: string | null;
  sets: loadOrders_orders_items_sets[] | null;
  extended: boolean;
}

export interface loadOrders_orders {
  __typename: 'OrderPageResult';
  totalCount: number;
  items: loadOrders_orders_items[];
}

export interface loadOrders {
  orders: loadOrders_orders;
}

export interface loadOrdersVariables {
  offset?: number | null;
  limit?: number | null;
  sort?: string | null;
  order?: string | null;
  from?: DateLimit | null;
  to?: DateLimit | null;
  state?: (string | null)[] | null;
  source?: (ReservationSource | null)[] | null;
  search?: string | null;
  unreturned?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOrder
// ====================================================

export interface createOrder_createOrder {
  __typename: 'Order';
  id: number;
}

export interface createOrder {
  createOrder: createOrder_createOrder | null;
}

export interface createOrderVariables {
  order?: OrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createReservation
// ====================================================

export interface createReservation_createReservation {
  __typename: 'OrderWithPayment';
  paymentUrl: string;
}

export interface createReservation {
  createReservation: createReservation_createReservation;
}

export interface createReservationVariables {
  order: OrderInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: itemPrices
// ====================================================

export interface itemPrices_itemPrices {
  __typename: 'ItemPrice';
  id: number;
  name: string | null;
  variant: string | null;
  oneDayPrice: number | null;
  twoDayPrice: number | null;
  basePrice: number | null;
  bail: number | null;
  seasonPrice: number | null;
  twoSeasonPrice: number | null;
  seasonBail: number | null;
  fixedPrice: boolean;
}

export interface itemPrices {
  itemPrices: (itemPrices_itemPrices | null)[] | null;
}

export interface itemPricesVariables {
  variant?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSetPrices
// ====================================================

export interface GetSetPrices_getSetPrices {
  __typename: 'SetPrice';
  id: number;
  name: string | null;
  variant: string | null;
  oneDayPrice: number | null;
  twoDayPrice: number | null;
  basePrice: number | null;
  seasonPrice: number | null;
  twoSeasonPrice: number | null;
  bail: number | null;
  imagePath: string | null;
  seasonBail: number | null;
}

export interface GetSetPrices {
  getSetPrices: (GetSetPrices_getSetPrices | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: setPrices
// ====================================================

export interface setPrices_setPrices {
  __typename: 'SetPrice';
  id: number;
  name: string | null;
  variant: string | null;
  oneDayPrice: number | null;
  twoDayPrice: number | null;
  basePrice: number | null;
  seasonPrice: number | null;
  twoSeasonPrice: number | null;
  bail: number | null;
  imagePath: string | null;
  seasonBail: number | null;
}

export interface setPrices {
  setPrices: (setPrices_setPrices | null)[] | null;
}

export interface setPricesVariables {
  variant?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CountBailBySetId
// ====================================================

export interface CountBailBySetId_countBailBySetId {
  __typename: 'PricesCountReturn';
  setId: number;
  amount: number;
}

export interface CountBailBySetId {
  countBailBySetId: CountBailBySetId_countBailBySetId;
}

export interface CountBailBySetIdVariables {
  setId: number;
  season: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CountPriceBySetId
// ====================================================

export interface CountPriceBySetId_countPriceBySetId {
  __typename: 'PricesCountReturn';
  setId: number;
  amount: number;
}

export interface CountPriceBySetId {
  countPriceBySetId: CountPriceBySetId_countPriceBySetId;
}

export interface CountPriceBySetIdVariables {
  setId: number;
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: categories
// ====================================================

export interface categories_categories {
  __typename: 'Category';
  id: number;
  name: string;
  imagePath: string | null;
  itemsExcludedFromSet: boolean;
}

export interface categories {
  categories: (categories_categories | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllowedCategory
// ====================================================

export interface AllowedCategory_allowedCategories {
  __typename: 'AllowedCategory';
  id: number;
}

export interface AllowedCategory {
  allowedCategories: (AllowedCategory_allowedCategories | null)[] | null;
}

export interface AllowedCategoryVariables {
  setPriceId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setAllowedCategories
// ====================================================

export interface setAllowedCategories {
  setAllowedCategories: boolean | null;
}

export interface setAllowedCategoriesVariables {
  setPriceId?: number | null;
  allowedCategories?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addPayment
// ====================================================

export interface addPayment_addPayment {
  __typename: 'Payment';
  amount: number;
}

export interface addPayment {
  addPayment: addPayment_addPayment | null;
}

export interface addPaymentVariables {
  orderId?: number | null;
  payment?: PaymentInput | null;
  isUpdate: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addPayments
// ====================================================

export interface addPayments_addPayments {
  __typename: 'Payment';
  amount: number;
}

export interface addPayments {
  addPayments: (addPayments_addPayments | null)[] | null;
}

export interface addPaymentsVariables {
  orderId?: number | null;
  payments?: (PaymentInput | null)[] | null;
  isUpdate: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addPaymentsAndCancelOrder
// ====================================================

export interface addPaymentsAndCancelOrder_addPaymentsAndCancelOrder {
  __typename: 'Payment';
  amount: number;
}

export interface addPaymentsAndCancelOrder {
  addPaymentsAndCancelOrder:
    | (addPaymentsAndCancelOrder_addPaymentsAndCancelOrder | null)[]
    | null;
}

export interface addPaymentsAndCancelOrderVariables {
  orderId?: number | null;
  payments?: (PaymentInput | null)[] | null;
  isUpdate: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setWithoutBail
// ====================================================

export interface setWithoutBail {
  setWithoutBail: boolean | null;
}

export interface setWithoutBailVariables {
  orderId?: number | null;
  withoutBail?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setDiscount
// ====================================================

export interface setDiscount {
  setDiscount: boolean | null;
}

export interface setDiscountVariables {
  orderId?: number | null;
  discount?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateOrderPriceOrBail
// ====================================================

export interface updateOrderPriceOrBail {
  updateOrderPriceOrBail: boolean | null;
}

export interface updateOrderPriceOrBailVariables {
  orderId?: number | null;
  amount?: number | null;
  valueType?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateState
// ====================================================

export interface updateState_updateState {
  __typename: 'Order';
  id: number;
  state: OrderState;
}

export interface updateState {
  updateState: updateState_updateState;
}

export interface updateStateVariables {
  orderId?: number | null;
  state?: OrderState | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setItemPrice
// ====================================================

export interface setItemPrice {
  setItemPrice: boolean | null;
}

export interface setItemPriceVariables {
  price?: PriceInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setSetPrice
// ====================================================

export interface setSetPrice {
  setSetPrice: boolean | null;
}

export interface setSetPriceVariables {
  price?: PriceSetInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePayment
// ====================================================

export interface deletePayment {
  deletePayment: boolean | null;
}

export interface deletePaymentVariables {
  paymentId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOrder
// ====================================================

export interface deleteOrder {
  deleteOrder: boolean | null;
}

export interface deleteOrderVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelOrder
// ====================================================

export interface cancelOrder {
  cancelOrder: boolean | null;
}

export interface cancelOrderVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeOrder
// ====================================================

export interface ChangeOrder {
  changeOrder: boolean | null;
}

export interface ChangeOrderVariables {
  orderId: number;
  sets: OrderChangeSets;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConfigQuery
// ====================================================

export interface ConfigQuery_configQuery {
  __typename: 'Config';
  seasonEnabled: boolean;
  twoSeasonsEnabled: boolean;
  addressDeliveryDisabled: boolean;
  isReservationDisabled: boolean;
}

export interface ConfigQuery {
  configQuery: ConfigQuery_configQuery;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfigUpdate
// ====================================================

export interface ConfigUpdate_configUpdate {
  __typename: 'Config';
  seasonEnabled: boolean;
  twoSeasonsEnabled: boolean;
  addressDeliveryDisabled: boolean;
  isReservationDisabled: boolean;
}

export interface ConfigUpdate {
  configUpdate: ConfigUpdate_configUpdate;
}

export interface ConfigUpdateVariables {
  input: ConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClosedDay
// ====================================================

export interface deleteClosedDay {
  deleteClosedDay: boolean | null;
}

export interface deleteClosedDayVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Payments
// ====================================================

export interface Payments_payments_sums {
  __typename: 'SumsOfPayments';
  priceSum: number | null;
  bailSum: number | null;
  repaySum: number | null;
  repaySumPayment: number | null;
  cashSum: number | null;
  cardSum: number | null;
  invoiceSum: number | null;
  onlineSum: number | null;
}

export interface Payments_payments_payments_user {
  __typename: 'User';
  name: string | null;
  username: string | null;
}

export interface Payments_payments_payments_order {
  __typename: 'Order';
  id: number;
}

export interface Payments_payments_payments {
  __typename: 'Payment';
  id: string;
  amount: number;
  type: PaymentType;
  form: PaymentForm | null;
  createdAt: any;
  user: Payments_payments_payments_user | null;
  order: Payments_payments_payments_order | null;
}

export interface Payments_payments {
  __typename: 'PaymentPageResult';
  totalCount: number;
  sums: Payments_payments_sums | null;
  payments: (Payments_payments_payments | null)[] | null;
}

export interface Payments {
  payments: Payments_payments | null;
}

export interface PaymentsVariables {
  limit?: number | null;
  offset?: number | null;
  from?: any | null;
  to?: any | null;
  price?: boolean | null;
  bail?: boolean | null;
  repay?: boolean | null;
  repayPrice?: boolean | null;
  cash?: boolean | null;
  card?: boolean | null;
  invoice?: boolean | null;
  online?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CatalogItem
// ====================================================

export interface CatalogItem_catalogItem_stockItems_author_user {
  __typename: 'User';
  id: number;
  username: string | null;
}

export interface CatalogItem_catalogItem_stockItems_author {
  __typename: 'Employee';
  user: CatalogItem_catalogItem_stockItems_author_user | null;
}

export interface CatalogItem_catalogItem_stockItems {
  __typename: 'StockItemDetail';
  id: number | null;
  code: string | null;
  createdAt: any | null;
  updatedAt: any | null;
  deleted: boolean;
  author: CatalogItem_catalogItem_stockItems_author | null;
}

export interface CatalogItem_catalogItem_orders_customer {
  __typename: 'Customer';
  id: string;
  name: string;
  phone: string;
}

export interface CatalogItem_catalogItem_orders {
  __typename: 'Order';
  id: number;
  from: any;
  to: any;
  state: OrderState;
  customer: CatalogItem_catalogItem_orders_customer;
}

export interface CatalogItem_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  producer: string;
  purpose: string | null;
  size: string;
  selectSize: number | null;
  variant: string;
  categoryName: string | null;
  description: string | null;
  universal: boolean | null;
  seasonDisabled: boolean | null;
  stockItems: CatalogItem_catalogItem_stockItems[] | null;
  orders: CatalogItem_catalogItem_orders[] | null;
}

export interface CatalogItem {
  catalogItem: CatalogItem_catalogItem | null;
}

export interface CatalogItemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPricingForInterval
// ====================================================

export interface GetPricingForInterval_getPricingForInterval {
  __typename: 'CatalogPricing';
  price: number;
  bail: number;
}

export interface GetPricingForInterval {
  getPricingForInterval: GetPricingForInterval_getPricingForInterval;
}

export interface GetPricingForIntervalVariables {
  catalogId: number;
  from: any;
  to: any;
  set: boolean;
  season: boolean;
  morningPickUp: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Discounts
// ====================================================

export interface Discounts_discounts_discounts {
  __typename: 'Discount';
  id: number;
  role: EmployeeRole | null;
  discount: number | null;
}

export interface Discounts_discounts {
  __typename: 'DiscountsPageResult';
  discounts: (Discounts_discounts_discounts | null)[] | null;
}

export interface Discounts {
  discounts: Discounts_discounts | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrderBail
// ====================================================

export interface OrderBail_order_payments {
  __typename: 'Payment';
  amount: number;
  type: PaymentType;
}

export interface OrderBail_order_amounts {
  __typename: 'Amounts';
  toPayPrice: number;
  toReturnBail: number;
  newPrice: number;
  bail: number;
  price: number;
  returnBail: number;
  returnPrice: number;
}

export interface OrderBail_order_discount {
  __typename: 'Discount';
  id: number;
  discount: number | null;
}

export interface OrderBail_order_sets_items_firstItem_catalogItem {
  __typename: 'CatalogItem';
  name: string;
  size: string;
  variant: string;
  producer: string;
  categoryName: string | null;
}

export interface OrderBail_order_sets_items_firstItem {
  __typename: 'StockItem';
  code: string;
  catalogItem: OrderBail_order_sets_items_firstItem_catalogItem;
}

export interface OrderBail_order_sets_items_secondItem_catalogItem {
  __typename: 'CatalogItem';
  name: string;
  size: string;
  variant: string;
  producer: string;
  categoryName: string | null;
}

export interface OrderBail_order_sets_items_secondItem {
  __typename: 'StockItem';
  code: string;
  catalogItem: OrderBail_order_sets_items_secondItem_catalogItem;
}

export interface OrderBail_order_sets_items {
  __typename: 'StockOrder';
  firstItemReturnedDate: any | null;
  secondItemReturnedDate: any | null;
  firstItemReturned: boolean | null;
  secondItemReturned: boolean | null;
  firstItem: OrderBail_order_sets_items_firstItem;
  secondItem: OrderBail_order_sets_items_secondItem | null;
}

export interface OrderBail_order_sets {
  __typename: 'Set';
  items: OrderBail_order_sets_items[] | null;
}

export interface OrderBail_order {
  __typename: 'Order';
  id: number;
  bail: number | null;
  price: number | null;
  to: any;
  serviceNote: string | null;
  state: OrderState;
  payments: (OrderBail_order_payments | null)[] | null;
  amounts: OrderBail_order_amounts | null;
  withoutBail: boolean | null;
  discount: OrderBail_order_discount | null;
  sets: OrderBail_order_sets[] | null;
}

export interface OrderBail {
  order: OrderBail_order | null;
}

export interface OrderBailVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Order
// ====================================================

export interface Order_order_payments {
  __typename: 'Payment';
  amount: number;
  type: PaymentType;
}

export interface Order_order_discount {
  __typename: 'Discount';
  id: number;
  discount: number | null;
}

export interface Order_order {
  __typename: 'Order';
  id: number;
  bail: number | null;
  from: any;
  to: any;
  state: OrderState;
  season: boolean | null;
  price: number | null;
  payments: (Order_order_payments | null)[] | null;
  withoutBail: boolean | null;
  discount: Order_order_discount | null;
  morningPickUp: boolean | null;
  eveningReturn: boolean | null;
}

export interface Order {
  order: Order_order | null;
}

export interface OrderVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BailsToReturn
// ====================================================

export interface BailsToReturn_bailsToReturn_sums {
  __typename: 'SumsOfBails';
  cashSum: number;
  cardSum: number;
  invoiceSum: number;
}

export interface BailsToReturn_bailsToReturn {
  __typename: 'BailsReturn';
  totalCount: number | null;
  sums: BailsToReturn_bailsToReturn_sums;
}

export interface BailsToReturn {
  bailsToReturn: BailsToReturn_bailsToReturn;
}

export interface BailsToReturnVariables {
  date: DateLimit;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CatalogGroups
// ====================================================

export interface CatalogGroups_catalogGroups {
  __typename: 'CatalogGroupsResult';
  id: string;
  name: string | null;
  code: string;
}

export interface CatalogGroups {
  catalogGroups: CatalogGroups_catalogGroups[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSize
// ====================================================

export interface DeleteSize {
  deleteSize: number;
}

export interface DeleteSizeVariables {
  sizeId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SizesCount
// ====================================================

export interface SizesCount {
  getSizesCount: number;
}

export interface SizesCountVariables {
  catalogGroupName?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Sizes
// ====================================================

export interface Sizes_getSizes_catalogGroups {
  __typename: 'CatalogGroupsResult';
  id: string;
  name: string | null;
  code: string;
}

export interface Sizes_getSizes {
  __typename: 'Size';
  id: string | null;
  heightMin: number | null;
  heightMax: number | null;
  weightMin: number | null;
  weightMax: number | null;
  footSize: number | null;
  min: number | null;
  max: number | null;
  size: string | null;
  count: number | null;
  catalogGroups: Sizes_getSizes_catalogGroups[];
}

export interface Sizes {
  getSizes: (Sizes_getSizes | null)[] | null;
}

export interface SizesVariables {
  catalogGroupName?: string | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Filters
// ====================================================

export interface Filters_getFilters_catalogGroups {
  __typename: 'CatalogGroupsResult';
  id: string;
  name: string | null;
  code: string;
}

export interface Filters_getFilters {
  __typename: 'Filter';
  id: string;
  basketSetType: BasketSetType | null;
  catalogItemType: CatalogItemType | null;
  ccsPurpose: CCSPurpose | null;
  ccsStyle: CCSStyle | null;
  sex: Sex | null;
  skiArc: SkiArc | null;
  skills: Skills | null;
  skiSlope: SkiSlope | null;
  catalogGroups: Filters_getFilters_catalogGroups[];
}

export interface Filters {
  getFilters: (Filters_getFilters | null)[] | null;
}

export interface FiltersVariables {
  input?: FilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFilters
// ====================================================

export interface GetFilters_filters {
  __typename: 'Filter';
  id: string;
  basketSetType: BasketSetType | null;
  catalogItemType: CatalogItemType | null;
  ccsPurpose: CCSPurpose | null;
  ccsStyle: CCSStyle | null;
  sex: Sex | null;
  skiArc: SkiArc | null;
  skills: Skills | null;
  skiSlope: SkiSlope | null;
}

export interface GetFilters {
  filters: (GetFilters_filters | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFilterById
// ====================================================

export interface GetFilterById_getFilterById_catalogGroups {
  __typename: 'CatalogGroupsResult';
  id: string;
  code: string;
}

export interface GetFilterById_getFilterById {
  __typename: 'Filter';
  id: string;
  basketSetType: BasketSetType | null;
  catalogItemType: CatalogItemType | null;
  ccsPurpose: CCSPurpose | null;
  ccsStyle: CCSStyle | null;
  sex: Sex | null;
  skiArc: SkiArc | null;
  skills: Skills | null;
  skiSlope: SkiSlope | null;
  catalogGroups: GetFilterById_getFilterById_catalogGroups[];
}

export interface GetFilterById {
  getFilterById: (GetFilterById_getFilterById | null)[] | null;
}

export interface GetFilterByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MutateFilter
// ====================================================

export interface MutateFilter_mutateFilter {
  __typename: 'Filter';
  id: string;
}

export interface MutateFilter {
  mutateFilter: MutateFilter_mutateFilter;
}

export interface MutateFilterVariables {
  input: MutateItemFilter;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetSize
// ====================================================

export interface SetSize_setSize {
  __typename: 'Size';
  id: string | null;
}

export interface SetSize {
  setSize: SetSize_setSize;
}

export interface SetSizeVariables {
  input: SizeInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CatalogItemsInCatalogGroup
// ====================================================

export interface CatalogItemsInCatalogGroup_catalogItemsSizeIndependent_items_category {
  __typename: 'Category';
  id: number;
  name: string;
}

export interface CatalogItemsInCatalogGroup_catalogItemsSizeIndependent_items {
  __typename: 'CatalogItemSizeIndependent';
  id: string;
  name: string;
  purpose: string;
  producer: string;
  variant: string;
  positionInCatalogGroup: number | null;
  category: CatalogItemsInCatalogGroup_catalogItemsSizeIndependent_items_category;
  ids: string[];
  sizes: string[];
}

export interface CatalogItemsInCatalogGroup_catalogItemsSizeIndependent {
  __typename: 'CatalogSizeIndependentPageResult';
  totalCount: number;
  items: CatalogItemsInCatalogGroup_catalogItemsSizeIndependent_items[];
}

export interface CatalogItemsInCatalogGroup {
  catalogItemsSizeIndependent: CatalogItemsInCatalogGroup_catalogItemsSizeIndependent;
}

export interface CatalogItemsInCatalogGroupVariables {
  input: CatalogItemsSizeIndependentInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogItemDescription
// ====================================================

export interface GetCatalogItemDescription_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  description: string | null;
}

export interface GetCatalogItemDescription {
  catalogItem: GetCatalogItemDescription_catalogItem | null;
}

export interface GetCatalogItemDescriptionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetItemDescription
// ====================================================

export interface SetItemDescription_setCatalogItemsDescription {
  __typename: 'CatalogItem';
  id: number;
}

export interface SetItemDescription {
  setCatalogItemsDescription: SetItemDescription_setCatalogItemsDescription[];
}

export interface SetItemDescriptionVariables {
  catalogItemIds: string[];
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomers
// ====================================================

export interface GetCustomers_customers {
  __typename: 'Customer';
  id: string;
  email: string | null;
  name: string;
  phone: string;
}

export interface GetCustomers {
  customers: GetCustomers_customers[];
}

export interface GetCustomersVariables {
  name?: string | null;
  phone?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCashRegisterRecords
// ====================================================

export interface GetCashRegisterRecords_cashRegisterRecords {
  __typename: 'CashRegisterRecord';
  id: string;
  openId: string;
  closedId: string | null;
  openedAt: any;
  closedAt: any | null;
  openWithAmount: number;
  closedWithAmount: number | null;
  closedWithDifference: boolean | null;
  differenceResolved: boolean | null;
  closedDifference: number | null;
}

export interface GetCashRegisterRecords {
  cashRegisterRecords: GetCashRegisterRecords_cashRegisterRecords[];
}

export interface GetCashRegisterRecordsVariables {
  date?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCashRegister
// ====================================================

export interface GetCashRegister_cashRegister_CashRegister_employee_user {
  __typename: 'User';
  name: string | null;
}

export interface GetCashRegister_cashRegister_CashRegister_employee {
  __typename: 'Employee';
  id: number;
  user: GetCashRegister_cashRegister_CashRegister_employee_user | null;
}

export interface GetCashRegister_cashRegister_CashRegister {
  __typename: 'CashRegister';
  id: string;
  createdAt: any;
  amount: number;
  recordType: RecordType;
  closedWithDifference: boolean | null;
  differenceResolved: boolean | null;
  closedDifference: number | null;
  employee: GetCashRegister_cashRegister_CashRegister_employee;
}

export interface GetCashRegister_cashRegister_Payment_user {
  __typename: 'User';
  name: string | null;
}

export interface GetCashRegister_cashRegister_Payment {
  __typename: 'Payment';
  id: string;
  createdAt: any;
  amount: number;
  type: PaymentType;
  user: GetCashRegister_cashRegister_Payment_user | null;
}

export type GetCashRegister_cashRegister =
  | GetCashRegister_cashRegister_CashRegister
  | GetCashRegister_cashRegister_Payment;

export interface GetCashRegister {
  cashRegister: GetCashRegister_cashRegister[];
}

export interface GetCashRegisterVariables {
  cashRegisterRecordId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddRecordToCashRegister
// ====================================================

export interface AddRecordToCashRegister_addRecordToCashRegister {
  __typename: 'CashRegister';
  id: string;
}

export interface AddRecordToCashRegister {
  addRecordToCashRegister: AddRecordToCashRegister_addRecordToCashRegister;
}

export interface AddRecordToCashRegisterVariables {
  recordType: RecordType;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CashRegisterDifferenceResolved
// ====================================================

export interface CashRegisterDifferenceResolved_cashRegisterDifferenceResolved {
  __typename: 'CashRegisterRecord';
  id: string;
  differenceResolved: boolean | null;
}

export interface CashRegisterDifferenceResolved {
  cashRegisterDifferenceResolved: CashRegisterDifferenceResolved_cashRegisterDifferenceResolved;
}

export interface CashRegisterDifferenceResolvedVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetOrderNote
// ====================================================

export interface SetOrderNote_setOrderNote {
  __typename: 'Order';
  id: number;
  note: string | null;
}

export interface SetOrderNote {
  setOrderNote: SetOrderNote_setOrderNote | null;
}

export interface SetOrderNoteVariables {
  orderId: number;
  note?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLastCashRecord
// ====================================================

export interface GetLastCashRecord_lastCashRecord {
  __typename: 'CashRegisterRecord';
  closedId: string | null;
  openId: string;
  id: string;
  openedAt: any;
  closedAt: any | null;
  openWithAmount: number;
  closedWithAmount: number | null;
  closedWithDifference: boolean | null;
  closedDifference: number | null;
  differenceResolved: boolean | null;
}

export interface GetLastCashRecord {
  lastCashRecord: GetLastCashRecord_lastCashRecord | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrder
// ====================================================

export interface GetOrder_order_deliveryAddress {
  __typename: 'DeliveryAddress';
  id: string;
  streetName: string;
  streetNumber: string;
  city: string;
  zipCode: string;
  customerId: number;
}

export interface GetOrder_order_customer {
  __typename: 'Customer';
  id: string;
  name: string;
  phone: string;
  email: string | null;
}

export interface GetOrder_order_sets_items_firstItem_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  variant: string;
  producer: string;
  categoryName: string | null;
  size: string;
  createsSet: boolean | null;
  pair: boolean | null;
}

export interface GetOrder_order_sets_items_firstItem {
  __typename: 'StockItem';
  id: number;
  code: string;
  catalogItem: GetOrder_order_sets_items_firstItem_catalogItem;
}

export interface GetOrder_order_sets_items_secondItem {
  __typename: 'StockItem';
  id: number;
  code: string;
}

export interface GetOrder_order_sets_items {
  __typename: 'StockOrder';
  id: number;
  firstItem: GetOrder_order_sets_items_firstItem;
  secondItem: GetOrder_order_sets_items_secondItem | null;
}

export interface GetOrder_order_sets {
  __typename: 'Set';
  id: number;
  price: number | null;
  bail: number | null;
  category: string | null;
  items: GetOrder_order_sets_items[] | null;
}

export interface GetOrder_order_payments {
  __typename: 'Payment';
  id: string;
  amount: number;
  type: PaymentType;
  form: PaymentForm | null;
  createdAt: any;
}

export interface GetOrder_order_discount {
  __typename: 'Discount';
  id: number;
  discount: number | null;
}

export interface GetOrder_order {
  __typename: 'Order';
  id: number;
  from: any;
  to: any;
  note: string | null;
  serviceNote: string | null;
  state: OrderState;
  season: boolean | null;
  deliveryAddress: GetOrder_order_deliveryAddress | null;
  customer: GetOrder_order_customer;
  sets: GetOrder_order_sets[] | null;
  lostAndFound: boolean | null;
  bail: number | null;
  price: number | null;
  payments: (GetOrder_order_payments | null)[] | null;
  withoutBail: boolean | null;
  discount: GetOrder_order_discount | null;
  morningPickUp: boolean | null;
  eveningReturn: boolean | null;
  orderOriginator: string | null;
  paymentUrl: string | null;
  extended: boolean;
}

export interface GetOrder {
  order: GetOrder_order | null;
}

export interface GetOrderVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrderDetail
// ====================================================

export interface GetOrderDetail_order_payments {
  __typename: 'Payment';
  id: string;
  amount: number;
  type: PaymentType;
  form: PaymentForm | null;
  createdAt: any;
}

export interface GetOrderDetail_order_discount {
  __typename: 'Discount';
  id: number;
  discount: number | null;
}

export interface GetOrderDetail_order_customer {
  __typename: 'Customer';
  id: string;
  name: string;
  phone: string;
  email: string | null;
}

export interface GetOrderDetail_order_sets_items_firstItem_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  variant: string;
  producer: string;
  categoryName: string | null;
  size: string;
}

export interface GetOrderDetail_order_sets_items_firstItem {
  __typename: 'StockItem';
  id: number;
  code: string;
  catalogItem: GetOrderDetail_order_sets_items_firstItem_catalogItem;
}

export interface GetOrderDetail_order_sets_items_secondItem {
  __typename: 'StockItem';
  id: number;
  code: string;
}

export interface GetOrderDetail_order_sets_items {
  __typename: 'StockOrder';
  id: number;
  firstItem: GetOrderDetail_order_sets_items_firstItem;
  secondItem: GetOrderDetail_order_sets_items_secondItem | null;
}

export interface GetOrderDetail_order_sets {
  __typename: 'Set';
  id: number;
  price: number | null;
  bail: number | null;
  category: string | null;
  items: GetOrderDetail_order_sets_items[] | null;
}

export interface GetOrderDetail_order {
  __typename: 'Order';
  id: number;
  from: any;
  to: any;
  bail: number | null;
  price: number | null;
  state: OrderState;
  withoutBail: boolean | null;
  note: string | null;
  payments: (GetOrderDetail_order_payments | null)[] | null;
  discount: GetOrderDetail_order_discount | null;
  customer: GetOrderDetail_order_customer;
  sets: GetOrderDetail_order_sets[] | null;
}

export interface GetOrderDetail {
  order: GetOrderDetail_order | null;
}

export interface GetOrderDetailVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindStockItem
// ====================================================

export interface FindStockItem_findStockItem_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  createsSet: boolean | null;
  size: string;
  variant: string;
  producer: string;
  categoryName: string | null;
  pair: boolean | null;
}

export interface FindStockItem_findStockItem_activeOrder {
  __typename: 'Order';
  id: number;
}

export interface FindStockItem_findStockItem_lostAndFoundOrder {
  __typename: 'Order';
  id: number;
}

export interface FindStockItem_findStockItem {
  __typename: 'StockItem';
  id: number;
  code: string;
  catalogItem: FindStockItem_findStockItem_catalogItem;
  activeOrder: FindStockItem_findStockItem_activeOrder | null;
  lostAndFoundOrder: FindStockItem_findStockItem_lostAndFoundOrder | null;
  deleted: boolean;
}

export interface FindStockItem {
  findStockItem: FindStockItem_findStockItem | null;
}

export interface FindStockItemVariables {
  code: string;
  deleted: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindStockItemPriceInterval
// ====================================================

export interface FindStockItemPriceInterval_findStockItemPriceInterval_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  createsSet: boolean | null;
  size: string;
  variant: string;
  producer: string;
  categoryName: string | null;
  pair: boolean | null;
}

export interface FindStockItemPriceInterval_findStockItemPriceInterval {
  __typename: 'StockItemPricing';
  id: number;
  code: string;
  catalogItem: FindStockItemPriceInterval_findStockItemPriceInterval_catalogItem;
  deleted: boolean;
  price: number;
  bail: number;
}

export interface FindStockItemPriceInterval {
  findStockItemPriceInterval: FindStockItemPriceInterval_findStockItemPriceInterval | null;
}

export interface FindStockItemPriceIntervalVariables {
  code: string;
  from: any;
  to: any;
  set: boolean;
  season: boolean;
  morningPickUp: boolean;
  deleted: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindStockItemsLike
// ====================================================

export interface FindStockItemsLike_findStockItemsLike_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  size: string;
  variant: string;
  producer: string;
  categoryName: string | null;
}

export interface FindStockItemsLike_findStockItemsLike_activeOrder {
  __typename: 'Order';
  id: number;
}

export interface FindStockItemsLike_findStockItemsLike_lostAndFoundOrder {
  __typename: 'Order';
  id: number;
}

export interface FindStockItemsLike_findStockItemsLike {
  __typename: 'StockItem';
  id: number;
  code: string;
  catalogItem: FindStockItemsLike_findStockItemsLike_catalogItem;
  activeOrder: FindStockItemsLike_findStockItemsLike_activeOrder | null;
  lostAndFoundOrder: FindStockItemsLike_findStockItemsLike_lostAndFoundOrder | null;
  deleted: boolean;
}

export interface FindStockItemsLike {
  findStockItemsLike: (FindStockItemsLike_findStockItemsLike | null)[];
}

export interface FindStockItemsLikeVariables {
  codeLike: string;
  deleted: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindStockPairItemsLike
// ====================================================

export interface FindStockPairItemsLike_findStockPairItemsLike_catalogItem {
  __typename: 'CatalogItem';
  id: number;
  name: string;
  size: string;
  variant: string;
  producer: string;
  categoryName: string | null;
}

export interface FindStockPairItemsLike_findStockPairItemsLike_activeOrder {
  __typename: 'Order';
  id: number;
}

export interface FindStockPairItemsLike_findStockPairItemsLike_lostAndFoundOrder {
  __typename: 'Order';
  id: number;
}

export interface FindStockPairItemsLike_findStockPairItemsLike {
  __typename: 'StockItem';
  id: number;
  code: string;
  catalogItem: FindStockPairItemsLike_findStockPairItemsLike_catalogItem;
  activeOrder: FindStockPairItemsLike_findStockPairItemsLike_activeOrder | null;
  lostAndFoundOrder: FindStockPairItemsLike_findStockPairItemsLike_lostAndFoundOrder | null;
  deleted: boolean;
}

export interface FindStockPairItemsLike {
  findStockPairItemsLike: (FindStockPairItemsLike_findStockPairItemsLike | null)[];
}

export interface FindStockPairItemsLikeVariables {
  catalogId: number;
  codeLike: string;
  deleted: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StockUsageQuery
// ====================================================

export interface StockUsageQuery_stockUsage_items {
  __typename: 'StockUsageItem';
  id: string;
  code: string;
  catalogId: string;
  catalogName: string;
  catalogSize: string;
  days: number;
  years: number;
}

export interface StockUsageQuery_stockUsage {
  __typename: 'StockUsageResult';
  totalCount: number;
  items: StockUsageQuery_stockUsage_items[];
}

export interface StockUsageQuery {
  stockUsage: StockUsageQuery_stockUsage;
}

export interface StockUsageQueryVariables {
  limit?: number | null;
  offset?: number | null;
  searchCode?: string | null;
  searchCatalog?: string | null;
  searchSize?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPriceForFollowingOrderQuery
// ====================================================

export interface GetPriceForFollowingOrderQuery_priceForFollowingOrder_setsInOrder {
  __typename: 'FollowingOrder';
  price: number | null;
  setId: number | null;
  bail: number | null;
}

export interface GetPriceForFollowingOrderQuery_priceForFollowingOrder_conflictOrders_customer {
  __typename: 'Customer';
  id: string;
  name: string;
  phone: string;
}

export interface GetPriceForFollowingOrderQuery_priceForFollowingOrder_conflictOrders {
  __typename: 'Order';
  id: number;
  from: any;
  to: any;
  state: OrderState;
  customer: GetPriceForFollowingOrderQuery_priceForFollowingOrder_conflictOrders_customer;
}

export interface GetPriceForFollowingOrderQuery_priceForFollowingOrder {
  __typename: 'FollowingOrders';
  setsInOrder:
    | (GetPriceForFollowingOrderQuery_priceForFollowingOrder_setsInOrder | null)[]
    | null;
  conflictOrders:
    | (GetPriceForFollowingOrderQuery_priceForFollowingOrder_conflictOrders | null)[]
    | null;
}

export interface GetPriceForFollowingOrderQuery {
  priceForFollowingOrder: GetPriceForFollowingOrderQuery_priceForFollowingOrder;
}

export interface GetPriceForFollowingOrderQueryVariables {
  from: any;
  to: any;
  orderId: number;
  season: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsOrderSeasonPriceDisabled
// ====================================================

export interface IsOrderSeasonPriceDisabled {
  isOrderSeasonPriceDisabled: boolean;
}

export interface IsOrderSeasonPriceDisabledVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CategoryEarningsQuery
// ====================================================

export interface CategoryEarningsQuery_categoryEarnings {
  __typename: 'CategoryEarnings';
  id: string;
  name: string;
  amount: number;
  stornoAmount: number;
}

export interface CategoryEarningsQuery {
  categoryEarnings: CategoryEarningsQuery_categoryEarnings[];
}

export interface CategoryEarningsQueryVariables {
  from: any;
  to: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogItemInfo
// ====================================================

export interface CatalogItemInfo_category {
  __typename: 'Category';
  id: number;
  name: string;
}

export interface CatalogItemInfo {
  __typename: 'CatalogItem';
  id: number;
  category: CatalogItemInfo_category;
  purpose: string | null;
  name: string;
  size: string;
  selectSize: number | null;
  variant: string;
  producer: string;
  note: string | null;
  pair: boolean | null;
  price: number | null;
  settings: boolean | null;
  createsSet: boolean | null;
  itemPriceId: number | null;
  setPriceId: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentFragment
// ====================================================

export interface PaymentFragment_user {
  __typename: 'User';
  name: string | null;
}

export interface PaymentFragment {
  __typename: 'Payment';
  type: PaymentType;
  user: PaymentFragment_user | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CashRegisterFragment
// ====================================================

export interface CashRegisterFragment_employee_user {
  __typename: 'User';
  name: string | null;
}

export interface CashRegisterFragment_employee {
  __typename: 'Employee';
  id: number;
  user: CashRegisterFragment_employee_user | null;
}

export interface CashRegisterFragment {
  __typename: 'CashRegister';
  recordType: RecordType;
  closedWithDifference: boolean | null;
  differenceResolved: boolean | null;
  closedDifference: number | null;
  employee: CashRegisterFragment_employee;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BasketSetType {
  CCS = 'CCS',
  SKI = 'SKI',
  SKIALP_FREERIDE = 'SKIALP_FREERIDE',
  SNOWBOARD = 'SNOWBOARD',
}

export enum CCSPurpose {
  GRIP = 'GRIP',
  SKIN = 'SKIN',
}

export enum CCSStyle {
  KLASIC = 'KLASIC',
  SCATE = 'SCATE',
}

export enum CatalogItemType {
  BACKPACK = 'BACKPACK',
  BAG = 'BAG',
  BAG_WITH_ABS = 'BAG_WITH_ABS',
  BOOTS = 'BOOTS',
  FINDER = 'FINDER',
  HEAD = 'HEAD',
  MAIN_ITEM = 'MAIN_ITEM',
  POLES = 'POLES',
}

/**
 * Employee
 */
export enum EmployeeRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
}

export enum OrderState {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  CHANGE = 'CHANGE',
  FINISHED = 'FINISHED',
  NEW = 'NEW',
  PREPARED = 'PREPARED',
  PREVIOUS = 'PREVIOUS',
  RESERVATION_CANCELED = 'RESERVATION_CANCELED',
  RESERVATION_NEW = 'RESERVATION_NEW',
  RESERVATION_PAID = 'RESERVATION_PAID',
  RESERVATION_PREPARED = 'RESERVATION_PREPARED',
}

export enum PaymentForm {
  CARD = 'CARD',
  CASH = 'CASH',
  INVOICE = 'INVOICE',
  ONLINE = 'ONLINE',
}

export enum PaymentType {
  BAIL = 'BAIL',
  DELIVERY = 'DELIVERY',
  PRICE = 'PRICE',
  RETURN = 'RETURN',
  RETURN_PRICE = 'RETURN_PRICE',
}

export enum RecordType {
  BALANCE_CLOSE = 'BALANCE_CLOSE',
  BALANCE_OPEN = 'BALANCE_OPEN',
  EXPENDITURE = 'EXPENDITURE',
  INCOME = 'INCOME',
}

export enum RecurringType {
  ONCE = 'ONCE',
  YEARLY = 'YEARLY',
}

export enum ReservationSource {
  ADMINISTRATION = 'ADMINISTRATION',
  ONLINE_RESERVATION = 'ONLINE_RESERVATION',
}

export enum Sex {
  CHILD = 'CHILD',
  MAN = 'MAN',
  WOMAN = 'WOMAN',
}

export enum SkiArc {
  LONG = 'LONG',
  SHORT = 'SHORT',
  UNIVERSAL = 'UNIVERSAL',
}

export enum SkiSlope {
  FREE = 'FREE',
  GROOMED = 'GROOMED',
}

export enum Skills {
  BEGINNER = 'BEGINNER',
  FREERIDE = 'FREERIDE',
  RECREATIONAL = 'RECREATIONAL',
  SPORT = 'SPORT',
}

export enum SnowboardPosition {
  BOTH = 'BOTH',
  LEFT_FRONT = 'LEFT_FRONT',
  RIGHT_FRONT = 'RIGHT_FRONT',
}

export interface CatalogItemsSizeIndependentInput {
  limit?: number | null;
  offset?: number | null;
  sort?: string | null;
  order?: string | null;
  search?: string | null;
  catalogGroupId?: number | null;
  withoutCatalogGroup?: boolean | null;
}

export interface ConfigInput {
  seasonEnabled?: boolean | null;
  twoSeasonsEnabled?: boolean | null;
  addressDeliveryDisabled?: boolean | null;
  isReservationDisabled?: boolean | null;
}

export interface CreateCatalogItemInput {
  universal?: boolean | null;
  seasonDisabled?: boolean | null;
  barCode?: string | null;
  categoryId?: number | null;
  purpose?: string | null;
  name?: string | null;
  size?: string | null;
  selectSize?: number | null;
  variant?: string | null;
  producer?: string | null;
  note?: string | null;
  pair?: boolean | null;
  createsSet?: boolean | null;
  settings?: boolean | null;
  price?: number | null;
  itemPriceId: number;
  setPriceId?: number | null;
}

export interface CustomerInput {
  name: string;
  id?: string | null;
  phone: string;
  email?: string | null;
}

export interface DateChangeInput {
  id: number;
  from?: any | null;
  to?: any | null;
  season?: boolean | null;
}

export interface DateLimit {
  start: any;
  end: any;
}

export interface DeliveryAddressInput {
  streetName?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  zipCode?: string | null;
}

export interface EditCategoryInput {
  id: string;
  name?: string | null;
  itemsExcludedFromSet?: boolean | null;
}

export interface FilterInput {
  id?: number | null;
  basketSetType?: BasketSetType | null;
  catalogItemType?: CatalogItemType | null;
  ccsPurpose?: CCSPurpose | null;
  ccsStyle?: CCSStyle | null;
  sex?: Sex | null;
  skiArc?: SkiArc | null;
  skills?: Skills | null;
  skiSlope?: SkiSlope | null;
}

export interface FilterValues {
  basketSetType?: BasketSetType | null;
  catalogItemType?: CatalogItemType | null;
  ccsStyle?: CCSStyle | null;
  ccsPurpose?: CCSPurpose | null;
  sex?: Sex | null;
  skills?: Skills | null;
  skiSlope?: SkiSlope | null;
  skiArc?: SkiArc | null;
}

export interface MutateItemFilter {
  id?: number | null;
  primaryCodes?: (string | null)[] | null;
  filters?: FilterValues | null;
}

export interface OrderChangeSet {
  id: number;
  setPriceName: string;
  stockCodes: string[][];
  changed: boolean;
}

/**
 * Orders
 */
export interface OrderChangeSets {
  sets: OrderChangeSet[];
  setsToRemove: (number | null)[];
  setlessItems: (string | null)[][];
}

export interface OrderInput {
  from: any;
  to: any;
  note?: string | null;
  customer?: CustomerInput | null;
  deliveryAddress?: DeliveryAddressInput | null;
  season?: boolean | null;
  morningPickUp?: boolean | null;
  pickupTimeId?: number | null;
}

export interface PaymentInput {
  amount?: number | null;
  type?: PaymentType | null;
  form?: PaymentForm | null;
  createdAt?: any | null;
}

export interface PriceInput {
  id: number;
  name?: string | null;
  variant?: string | null;
  oneDayPrice?: number | null;
  twoDayPrice?: number | null;
  basePrice?: number | null;
  bail?: number | null;
  seasonPrice?: number | null;
  seasonBail?: number | null;
  twoSeasonPrice?: number | null;
  fixedPrice?: boolean | null;
}

/**
 * Prices
 */
export interface PriceSetInput {
  id: number;
  name?: string | null;
  variant?: string | null;
  oneDayPrice?: number | null;
  twoDayPrice?: number | null;
  basePrice?: number | null;
  bail?: number | null;
  seasonPrice?: number | null;
  seasonBail?: number | null;
  twoSeasonPrice?: number | null;
}

export interface SizeInput {
  id?: string | null;
  heightMin?: number | null;
  heightMax?: number | null;
  weightMin?: number | null;
  weightMax?: number | null;
  footSize?: number | null;
  min?: number | null;
  max?: number | null;
  size?: string | null;
  catalogGroupNames: string[];
}

export interface UpdateCatalogItemInput {
  id: number;
  categoryId?: number | null;
  purpose?: string | null;
  name?: string | null;
  size?: string | null;
  selectSize?: number | null;
  variant?: string | null;
  producer?: string | null;
  note?: string | null;
  pair?: boolean | null;
  createsSet?: boolean | null;
  settings?: boolean | null;
  price?: number | null;
  itemPriceId: number;
  setPriceId?: number | null;
  seasonDisabled?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
